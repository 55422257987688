import "slick-carousel";
(function($) {
    "use strict";
    $(function() {
        $(".calendar__items").slick({
            arrows: true,
            slidesToShow: 7,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            variableWidth: true,
            prevArrow: '<span class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none"><path d="M14 2L2 14L14 26" stroke="#207F55" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
            nextArrow: '<span class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none"><path d="M2 26L14 14L2 2" stroke="#207F55" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToScroll: 7,
                }
            }],
        });
    });
})(jQuery);