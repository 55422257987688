import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import "@fancyapps/fancybox";
import "./foundation";
import "./fontAwesome";
import "./carousels";
import "./handleInputNumber";
import "./stickyFooter";
import "./replaceImgSvg";
import "./lazyLoadVideo";
import "./calendar";

import "../scss/style.scss";
(function($) {
    "use strict";
    $(function() {
        // example
    });
})(jQuery);