(function () {
  let $youtubeBlocks = $(".youtube__main");

  $youtubeBlocks.each(function () {
    const idOfVideo = $(this).data("embed");
    let source = "https://img.youtube.com/vi/" + idOfVideo + "/sddefault.jpg";

    $(this).append('<img src="' + source + '" alt="" class="youtube__img" />');

    $(this).click(function () {
      let iframe = document.createElement("iframe");
      iframe.className = "youtube__iframe";
      iframe.setAttribute("allowfullscreen", "");
      iframe.setAttribute(
        "src",
        "https://www.youtube.com/embed/" +
          idOfVideo +
          "?rel=0&showinfo=0&autoplay=1"
      );

      this.innerHTML = "";
      this.appendChild(iframe);
    });
  });
})();
